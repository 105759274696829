import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AlertModule, BreadcrumbModule, CardModule, ModalModule} from './components';
import {DataFilterPipe} from './components/data-table/data-filter.pipe';
import {TodoListRemoveDirective} from './components/todo/todo-list-remove.directive';
import {TodoCardCompleteDirective} from './components/todo/todo-card-complete.directive';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';

import {SpinnerComponent} from './components/spinner/spinner.component';
import {ApexChartComponent} from './components/chart/apex-chart/apex-chart.component';
import {ApexChartService} from './components/chart/apex-chart/apex-chart.service';
import {GalleryComponent} from './components/gallery/gallery.component';
import {LightboxModule} from 'ngx-lightbox';
import {SpinningDivComponent} from './components/spinning-div/spinning-div.component';
import {ToastMftComponent} from './components/toast/toast-mft.component';
import {ToastMftAreaComponent} from './components/toast/toast-mft-area.component';
import {ActivityListComponent} from './components/activity-list/activity-list.component';
import {RouterModule} from '@angular/router';
import {InputModalComponent} from './components/input-modal/input-modal.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {UserfulNumbersComponent} from './components/userful-numbers/userful-numbers.component';
import {
    CustomGuardDirective,
    GuardDirective,
    IsClientGuardDirective,
    RoleGuardDirective
} from "./directives/guard.directive";
import {AuthenticatedPolicy} from "../../services/guard-policies";

/*import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';*/

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        CardModule,
        BreadcrumbModule,
        ModalModule,
        ClickOutsideModule,
        LightboxModule,
        RouterModule,
    ],
    exports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        CardModule,
        BreadcrumbModule,
        ModalModule,
        DataFilterPipe,
        TodoListRemoveDirective,
        TodoCardCompleteDirective,
        ClickOutsideModule,
        SpinnerComponent,
        ApexChartComponent,
        GalleryComponent,
        SpinningDivComponent,
        ToastMftComponent,
        ToastMftAreaComponent,
        ActivityListComponent,
        InputModalComponent,
        ChangePasswordComponent,
        UserfulNumbersComponent,
        GuardDirective,
        RoleGuardDirective,
        CustomGuardDirective,
        IsClientGuardDirective
    ],
    declarations: [
        DataFilterPipe,
        TodoListRemoveDirective,
        TodoCardCompleteDirective,
        SpinnerComponent,
        ApexChartComponent,
        GalleryComponent,
        SpinningDivComponent,
        ToastMftComponent,
        ToastMftAreaComponent,
        ActivityListComponent,
        InputModalComponent,
        ChangePasswordComponent,
        UserfulNumbersComponent,
        GuardDirective,
        RoleGuardDirective,
        CustomGuardDirective,
        IsClientGuardDirective
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        ApexChartService
    ]
})
export class SharedModule {
}
