import {Component, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ProfileComm, ProfileLogin, ProfileResponse, TicketBookmark} from '../../../../models/responses/profile-data';
import {TicketHead} from '../../../../view/ticket/model/TicketResponse';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../../../shared/notification-service';

@Component({
    selector: 'app-activity-list',
    templateUrl: './activity-list.component.html',
    styleUrls: ['./activity-list.component.scss'],
})
export class ActivityListComponent implements OnInit, OnDestroy {
    private _datasource: ProfileResponse = null;

    private _subscriptionNotification: Subscription;
    private _showGeneralMessage = true;

    public items: ActivityItem[] = [];
    public ready = false;

    @Input()
    set showGeneralMessage(value: boolean) {
        this._showGeneralMessage = value;
    }

    get showGeneralMessage(): boolean {
        return this._showGeneralMessage;
    }

    @Input()
    set datasource(value: ProfileResponse) {
        if (value != null) {
            const cache = new Map<string, ActivityItem>();

            if (this._showGeneralMessage) {
                if (value.comms.err_active) {
                    cache.set('error', new NotifyItem(true, value.comms.err_msg));
                }
                if (value.comms.inf_active) {
                    cache.set('info', new NotifyItem(false, value.comms.inf_msg));
                }
            }
            for (const login of value.logins) {
                // for (let l = 0; l < value.logins.length; l++) {
                //    const login: ProfileLogin = value.logins[l];
                if (login.maints != null) {
                    for (const maint of login.maints) {
                        // for (let m = 0; m < login.maints.length; m++) {
                        //    const maint: TicketBookmark = login.maints[m];
                        cache.set(maint.id, new ActivityItem(maint, true, false));
                    }
                }
            }
            if (value.comms.unreads != null) {
                for (const unread of value.comms.unreads) {
                    // for (let u = 0; u < value.comms.unreads.length; u++) {
                    //    const unread: TicketBookmark = value.comms.unreads[u];
                    if (unread.id in cache) {
                        cache.get(unread.id).markUnread();
                    } else {
                        cache.set(unread.id, new ActivityItem(unread, false, true));
                    }
                }
            }

            const tmpItems: ActivityItem[] = [];
            /*for (const key in cache) {
                tmp.push(cache[key]);
            }*/
            for (const activityItem of cache.values()) {
                tmpItems.push(activityItem);
            }
            this.items = tmpItems;
            this._datasource = value;
        } else {
            this.items = [];
            this._datasource = null;
        }

        this.ready = this._datasource != null;
    }

    get datasource() {
        return this._datasource;
    }


    constructor(private notifications: NotificationService) {
    }

    ngOnInit(): void {
        this._subscriptionNotification = this.notifications.subscribe((profile) => {
            this.datasource = profile;
        });
    }

    ngOnDestroy(): void {
        this._subscriptionNotification.unsubscribe();
    }
}

class ActivityItem {
    public id: string;
    public icon: string = null;
    public iconColor: string = null;
    public icon2: string = null;
    public icon2Color: string = null;
    public link: string;
    public title: string;
    public subTitle: string;


    public constructor(item: TicketBookmark, isMaint: boolean, unread: boolean) {
        if (item != null) {
            this.id = item.id;
            if (isMaint) {
                this.icon = 'icon-triangle';
                this.iconColor = 'warning';
            }
            if (unread) {
                this.markUnread();
            }

            this.link = '/ticket/' + item.id;
            this.title = item.title;
            this.subTitle = 'Ticket';
        }
    }


    public markUnread() {

        this.icon2 = 'icon-mail';
        this.icon2Color = 'info';
    }

}

export class NotifyItem extends ActivityItem {
    public constructor(error: boolean, message: string) {
        super(null, false, false);
        this.link = '/dashboard';
        this.title = message.substr(0, 15) + '...';
        if (error) {
            this.subTitle = 'Attenzione';
            this.icon2 = 'icon-bell';
            this.icon2Color = 'danger';
        } else {
            this.subTitle = 'Informiamo';
            this.icon2 = 'icon-bell';
            this.icon2Color = 'info';
        }

    }
}


