import {ClientRole, LoginData} from '../models/responses/login-data';
import {Router} from '@angular/router';
import {deprecate} from 'util';
import {User} from '../models/user';

const STORE_LOGIN_DATA = 'store_login_data';
const STORAGE_TOKEN = 'store_token';
export const USER_DATA = 'user_data';
const STORE_SCREENSHOT_URL = 'store_screenshot_url';
const STORE_SCREENSHOT_URL_TIMELIFE = 'store_screenshot_url_timelife';

export class StorageService {
    // ===================================================================================
    //    PROPS
    // ===================================================================================
    private _loginData: LoginData = null;
    private _token: string = null;
    private _screenshotUrl: string = null;

    // ===================================================================================
    //    CONSTRUCTOR
    // ===================================================================================
    constructor(private router: Router) {
    }

    // ===================================================================================
    //    STATIC METHODS
    // ===================================================================================

    static _remove(key: string) {
        localStorage.removeItem(key);
    }

    static _clear() {
        localStorage.clear();
    }

    static _save(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    static _load(key: string): string {
        return localStorage.getItem(key);
    }

    static _loadData<T>(key: string): T {
        const value: string = StorageService._load(key);
        if (value == null || value.trim().length === 0) {
            return null;
        }
        return JSON.parse(value) as T;
    }


    static _saveData<T>(key: string, value: T) {
        if (value === null) {
            StorageService._remove(key);
        } else {
            StorageService._save(key, JSON.stringify(value));
        }
    }

    // ===================================================================================
    //    METHODS
    // ===================================================================================

    @deprecate
    private doUrlScreenshot() {
        this.screenshotUrl = this.router.url;
        this.loginData = null;
        this.router.navigate(['/']);
    }

    get loginData(): LoginData {
        if (this._loginData == null) {
            this._loginData = StorageService._loadData(STORE_LOGIN_DATA);
        }
        return this._loginData;
    }

    set loginData(loginData: LoginData) {
        this._loginData = loginData;
        StorageService._saveData(STORE_LOGIN_DATA, this._loginData);
    }

    get token(): string {
        if (this._token == null) {
            this._token = StorageService._loadData(STORAGE_TOKEN);
        }
        return this._token;
    }

    set token(token: string) {
        this._token = token;
        StorageService._saveData(STORAGE_TOKEN, this._token);
    }

    /*
    Salva nello storage le informazioni di un utente loggato
     */
    set userData(user: User) {
        localStorage.setItem(USER_DATA, JSON.stringify(user));
    }

    /*
    Restituisce i dati dell'utente autenticato.

    @return una istanza di User o null se l'utente non è autenticato
     */
    get userData() {
        return JSON.parse(localStorage.getItem(USER_DATA)) as User;
    }

    /*
    Restituisce il token di autorizzazione per il nuovo servizio di API.
    @return: il token o una stringa vuota se il token non esiste
     */
    get apiToken(): string {
        return (JSON.parse(localStorage.getItem(USER_DATA)) as User)?.token ?? '';
    }

    /*

    @return: true se l'utente è loggato, false altrimenti
     */
    get isLogged(): boolean {
        return localStorage.getItem(USER_DATA) !== null;
    }

    @deprecate
    private get screenshotUrl(): string {
        if (this._screenshotUrl == null) {
            const timeLife: Date = StorageService._loadData(STORE_SCREENSHOT_URL_TIMELIFE);
            const now = new Date();
            if (timeLife != null && now <= timeLife) {
                this._screenshotUrl = StorageService._loadData(STORE_SCREENSHOT_URL);
            }
            StorageService._remove(STORE_SCREENSHOT_URL_TIMELIFE);
            StorageService._remove(STORE_SCREENSHOT_URL);
        }
        return this._screenshotUrl;
    }

    private set screenshotUrl(url: string) {
        this._screenshotUrl = url;
        StorageService._saveData(STORE_SCREENSHOT_URL, url);
        const timeLife = new Date();
        timeLife.setHours(timeLife.getHours() + 1);
        StorageService._saveData(STORE_SCREENSHOT_URL_TIMELIFE, timeLife.toJSON());
    }

    // ===================================================================================
    //    PRIVATE METHODS
    // ===================================================================================

    // ===================================================================================
    //    HELPER METHODS
    // ===================================================================================
}
