import {StorageService} from '../shared/storage.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Role} from '../models/user';
import {Configs} from '../../configs';
import {RouteData} from '../app-routing.module';
import {AuthService} from '../shared/auth.service';
import {ClientRole} from '../models/responses/login-data';

export interface PolicyData {
    roles?: Role[];
    route?: ActivatedRouteSnapshot;
    state?: RouterStateSnapshot;
    fn?: (data: PolicyData) => boolean;
}

export abstract class GuardPolicy implements CanActivate {

    protected constructor(protected storage: StorageService, protected router: Router) {}

    abstract checkPolicy(data: PolicyData): boolean;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const data = route.data as RouteData;
        if (this.checkPolicy({roles: data.requiredRoles, route, state})) {
            return true;
        } else {
            this.router.navigate([Configs.loginPath], {queryParams: {previous: state.url}});
            return false;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class RolesPolicy extends GuardPolicy {
    constructor(protected storage: StorageService, protected router: Router) {
        super(storage, router);
    }
    checkPolicy(data: PolicyData): boolean {
        return !data.roles || data.roles.includes(this.storage.userData.role);
    }
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedPolicy extends GuardPolicy {
    constructor(protected storage: StorageService, protected router: Router) {
        super(storage, router);
    }
    checkPolicy(): boolean {
        return this.storage.isLogged;
    }
}

@Injectable({
    providedIn: 'root'
})
export class CustomPolicy extends GuardPolicy {
    constructor(protected storage: StorageService, protected router: Router) {
        super(storage, router);
    }

    checkPolicy(data): boolean {
        return data.fn(data);
    }
}


@Injectable({
    providedIn: 'root'
})
export class IsClientPolicy extends GuardPolicy {
    constructor(private auth: AuthService,
                protected storage: StorageService, protected router: Router) {
        super(storage, router);
    }

    checkPolicy(): boolean {
        return this.auth.hasRole(ClientRole.ROLE_CLIENT);
    }
}
