import {environment} from './environments/environment';

export class Configs {
  public static readonly loginPath = '/auth/signin-v2';
  public static readonly mainPath = '/';
  public static readonly idParam = 'id';
  public static readonly tokenParam = 'token';
  public static readonly loginParam = 'login';
  public static readonly administrativeParam = 'administrative';
}

