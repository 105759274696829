export interface ProfileResponse {
    idCliente: number;
    changedPassword: boolean;
    ragioneSociale: string;
    indirizzo?: ProfileAddress;
    amm?: ProfileContact;
    tec?: ProfileContact;
    fat?: ProfileContact;
    ass?: ProfilePremium;
    logins: ProfileLogin[];
    comms?: ProfileComm;
    modules?: ProfileModule[];

}

export interface ProfileAddress {
    civico?: string;
    indirizzo?: string;
    localita?: string;
    comune?: string;
    provincia?: string;
}

export interface ProfileContact {
    enabled?: boolean;
    user?: string;
    email?: string;
    sms?: string;
}

export interface ProfilePremium {
    assPremium: boolean;
    assPw: string;
}

export interface ProfileLogin {
    login: string;
    provider: string;
    maints?: TicketBookmark[];
    general_error?: boolean;
    general_info?: boolean;

}

export interface TicketBookmark {
    id?: string;
    title?: string;
    ts?: string;
}

export interface ProfileComm {
    err_msg?: string;
    err_active?: boolean;
    inf_msg?: string;
    inf_active?: string;
    unreads?: TicketBookmark [];
    maints?: boolean;
}

export interface ProfileModule {
    id?: string;
    enabled?: boolean;
    visible?: boolean;
}

export enum ModuleState {
    NOT_VISIBLE,
    VISIBLE,
    ENABLED
}


export function getModuleState(id: string, modules: ProfileModule[], defaultState = ModuleState.NOT_VISIBLE): ModuleState {
    if (id in modules) {
        const mod: ProfileModule = modules[id];
        if (!mod.visible) {
            return ModuleState.NOT_VISIBLE;
        }
        if (!mod.enabled) {
            return ModuleState.VISIBLE;
        }
        return ModuleState.ENABLED;
    }
    return defaultState;
}
