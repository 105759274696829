<div class="toast-area">
    <app-toast-mft *ngFor="let toast of events | async"
                   [toastId]="toast.id"
                   [toastCaption]="toast.caption"
                   [toastClass]="toast.extraClass"
                   [toastTitle]="toast.title"
                   [toastBody]="toast.body"
                   [toastTimeout]="toast.timeout"
    ></app-toast-mft>
</div>
