import {NgModule} from '@angular/core';
import {Routes, RouterModule, Route} from '@angular/router';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {SpeedTestComponent} from './view/speed-test/speed-test.component';
import {DocsAreaComponent} from './view/docs-area/docs-area.component';
import {AuthenticatedPolicy, RolesPolicy} from './services/guard-policies';
import {Role} from './models/user';
import {SellerDocumentationComponent} from "./view/seller-documentation/seller-documentation.component";
import {InfoPrivacyComponent} from './view/privacy/info-privacy.component';

export interface RouteData {
    requiredRoles: Role[];
}

interface RoutesWithPolicy extends Route {
    data?: RouteData;
}

const routes: RoutesWithPolicy[] = [
    {
        path: '',
        component: AdminComponent,
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./view/home/home.module').then(module => module.HomeModule),
                canActivate: [AuthenticatedPolicy]
            },
            {
                path: 'ticket',
                loadChildren: () => import('./view/ticket/ticket.module').then(module => module.TicketModule),
                canActivate: [AuthenticatedPolicy]
            },
            {
                path: 'invoices',
                loadChildren: () => import('./view/invoice/invoices.module').then(module => module.InvoicesModule),
                canActivate: [AuthenticatedPolicy]
            },
            {
                path: 'premium',
                loadChildren: () => import('./view/premium/premium.module').then(module => module.PremiumModule),
                canActivate: [AuthenticatedPolicy]
            },
            {
                path: 'tecnici',
                loadChildren: () => import('./view/tecnici/tecnici.module').then(module => module.TecniciModule),
                canActivate: [AuthenticatedPolicy]
            },
            {
                path: 'contatti',
                loadChildren: () => import('./view/contact/contact.module').then(module => module.ContactModule),
                canActivate: [AuthenticatedPolicy]
            },
            {
                path: 'speedtest', component: SpeedTestComponent, pathMatch: 'full',
                canActivate: [AuthenticatedPolicy]
            },
            {
                path: 'documentazione', component: SellerDocumentationComponent, pathMatch: 'full',
                data: {requiredRoles: [Role.RESELLER, Role.COMMERCIAL_AGENT]},
                canActivate: [RolesPolicy]
            },
            {
                path: 'docs', component: DocsAreaComponent, pathMatch: 'full',
                canActivate: [AuthenticatedPolicy]
            },
            {
                path: 'informativa-privacy', component: InfoPrivacyComponent, pathMatch: 'full',
                canActivate: []
            },
        ]
    },
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'auth',
                loadChildren: () => import('./view/authentication/authentication.module').then(module => module.AuthenticationModule)
            },
        ]
    },
    {
        path: '**',
        redirectTo: '/dashboard'
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
