import {Component, Input, OnInit} from '@angular/core';
import {MftToast, ToastMftService} from './toast-mft.service';

@Component({
    selector: 'app-toast-mft',
    templateUrl: './toast-mft.component.html',
    styleUrls: ['./toast-mft.component.scss'],
})
export class ToastMftComponent {
    @Input() toastId: number;
    @Input() toastTitle = '';
    @Input() toastCaption = '';
    @Input() toastClass = '';
    @Input() toastBody = '';
    @Input() toastTimeout = 5000;

    constructor(private toastService: ToastMftService) {
        setTimeout(() => this.closeToast(), this.toastTimeout);
    }

    closeToast() {
        this.toastService.removeToast(this.toastId);
    }

}
