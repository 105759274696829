import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {
    AuthenticatedPolicy,
    CustomPolicy, IsClientPolicy,
    PolicyData,
    RolesPolicy
} from '../../../services/guard-policies';
import {Role} from '../../../models/user';

@Directive({
    selector: '[appGuard]'
})
export class GuardDirective implements OnInit {
    constructor(private policy: AuthenticatedPolicy,
                private template: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    ngOnInit() {
        if (this.policy.checkPolicy()) {
            this.viewContainer.createEmbeddedView(this.template);
        } else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector: '[appRolesGuard]'
})
export class RoleGuardDirective implements OnInit {

    @Input() appRolesGuard: Role[] = [];

    constructor(private policy: RolesPolicy,
                private template: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    ngOnInit() {
        if (this.policy.checkPolicy({roles: this.appRolesGuard})) {
            this.viewContainer.createEmbeddedView(this.template);
        } else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector: '[appCustomGuard]'
})
export class CustomGuardDirective implements OnInit {

    @Input() appCustomGuard: (data: PolicyData) => boolean;

    constructor(private policy: CustomPolicy,
                private template: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    ngOnInit() {
        if (this.policy.checkPolicy({fn: this.appCustomGuard})) {
            this.viewContainer.createEmbeddedView(this.template);
        } else {
            this.viewContainer.clear();
        }
    }
}


@Directive({
    selector: '[appIsClientGuard]'
})
export class IsClientGuardDirective implements OnInit {
    private hasView = false;

    constructor(private policy: IsClientPolicy,
                private template: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    ngOnInit() {
        if (!this.hasView && this.policy.checkPolicy()) {
            this.viewContainer.createEmbeddedView(this.template);
            this.hasView = true;
        } else if (this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
