import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-documentation',
  templateUrl: './seller-documentation.component.html',
  styleUrls: ['./seller-documentation.component.scss']
})
export class SellerDocumentationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
