import {User} from '../user';

export interface LoginData {
    idCliente?: string;
    login?: string;
    ragioneSociale: string;
    idZucchetti?: string;
    dotNetUser?: User;

    clientRole?: ClientRole;

}

export enum ClientRole {
    ROLE_CLIENT = 'ROLE_CLIENT',
    ROLE_MFT = 'ROLE_MFT',
    ROLE_BOSS_AGENT = 'ROLE_BOSS_AGENT',
    ROLE_ZONE_AGENT = 'ROLE_ZONE_AGENT'
}
