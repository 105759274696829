import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../../../../shared/notification-service';
import {ProfileResponse} from '../../../../../models/responses/profile-data';
import {RestApiService} from '../../../../../shared/rest-api.service';
import {Router} from '@angular/router';
import {Configs} from '../../../../../../configs';
import {ChangePasswordComponent} from '../../../../shared/components/change-password/change-password.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-nav-right',
    templateUrl: './nav-right.component.html',
    styleUrls: ['./nav-right.component.scss'],
    providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit, OnDestroy {

    profile: ProfileResponse;
    public isTecnico:boolean=false;
    @ViewChild(ChangePasswordComponent)
    changePasswordComponent: ChangePasswordComponent;
    private subscription: Subscription;

    get notificationsCount(): number {
        let a= this.profile?.comms?.unreads?.length ?? 0;
        if (this.profile?.comms?.err_active)
            a++;
        if (this.profile?.comms?.inf_active)
            a++;
        return a;
    }

    get username(): string {
        return this.profile?.ragioneSociale ?? 'Loading';
    }

    constructor(private notificationService: NotificationService, private api: RestApiService,
                private router: Router) {
    }

    ngOnInit() {
        this.subscription = this.notificationService.subscribe((profile) => {
            this.profile = profile;
            this.isTecnico= profile.tec.user!=profile.amm.user;
        });
    }

    ngOnDestroy() {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
    }

    async logout() {
        const response = await this.api.doLogout();
        this.notificationService.clearCache();
        await this.router.navigateByUrl(Configs.loginPath);
    }

    changePassword() {
        this.changePasswordComponent.changePassword();
    }
}
