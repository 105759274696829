import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    OnInit, Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {ToastMftComponent} from './toast-mft.component';
import {MftToast, ToastMftService} from './toast-mft.service';
import {Observable, Observer} from "rxjs";

@Component({
    selector: 'app-toast-mft-area',
    templateUrl: './toast-mft-area.component.html',
    styleUrls: ['./toast-mft-area.component.scss'],
})
export class ToastMftAreaComponent implements OnInit {

    events: Observable<MftToast[]>;

    constructor(private toastMftService: ToastMftService) {
    }

    ngOnInit() {
        this.events = this.toastMftService.events;
    }

    clearToastArea(): void {
        this.toastMftService.clearToasts();
    }

}
