import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {NextConfig} from '../../../app-config';
import {Location} from '@angular/common';
import {RestApiService} from '../../../shared/rest-api.service';
import {NotificationService} from '../../../shared/notification-service';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';

declare var WebApp: any;

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
    private routerSubscription: Subscription;
    public nextConfig: any;
    public navCollapsed = false;
    public navCollapsedMob = false;
    public windowWidth: number;

    constructor(private zone: NgZone, private location: Location, private notificationService: NotificationService,
                private router: Router) {


        this.nextConfig = NextConfig.config;
        let currentURL = this.location.path();
        const baseHrefKey = '_baseHref';
        const baseHerf = this.location[baseHrefKey];
        if (baseHerf) {
            currentURL = baseHerf + this.location.path();
        }

        this.windowWidth = window.innerWidth;

        if ((currentURL === baseHerf + '/layout/collapse-menu'
            || currentURL === baseHerf + '/layout/box')
            && (this.windowWidth >= 992 && this.windowWidth <= 1024)) {
            this.nextConfig.collapseMenu = true;
        }

        this.navCollapsed = (this.windowWidth >= 992) ? this.nextConfig.collapseMenu : false;
        this.navCollapsedMob = false;

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                try {
                    if (WebApp != null && WebApp.postMessage) {
                        WebApp.postMessage(JSON.stringify({action: 'navigation', url: val.url}));
                    }
                } catch (e) {
                    console.log(e);
                }
                console.log('NAVIGATION END ' + val.url);
                this.updateNotifications();
            }
        });

        this.loopUpdateNotifications();

    }

    ngOnInit() {
        // Disabilita il menu verticale sui devices mobili
        if (this.windowWidth < 992) {
            this.nextConfig.layout = 'vertical';
            setTimeout(() => {
                document.querySelector('.pcoded-navbar').classList.add('menupos-static');
                (document.querySelector('#nav-ps-next') as HTMLElement).style.maxHeight = '100%'; // 100% amit
            }, 500);
        }
        //    this.updateNotifications();
    }

    ngOnDestroy() {
        this.routerSubscription?.unsubscribe();
    }

    async updateNotifications() {
        setTimeout(() => {
            this.notificationService.getNotifications(true);
        }, 1);
    }

    loopUpdateNotifications() {
        setTimeout(async () => {
            await this.notificationService.getNotifications(false);
            this.loopUpdateNotifications();
        }, environment.notificationsDeltaMinutes * 60 * 1000);
    }

    navMobClick() {
        if (this.windowWidth < 992) {
            if (this.navCollapsedMob && !(document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
                this.navCollapsedMob = !this.navCollapsedMob;
                setTimeout(() => {
                    this.navCollapsedMob = !this.navCollapsedMob;
                }, 100);
            } else {
                this.navCollapsedMob = !this.navCollapsedMob;
            }
        }
    }

}
