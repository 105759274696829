<app-navigation class="pcoded-navbar"
                [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.nextConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}"
                (onNavMobCollapse)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light header-blue"
             (onNavCollapse)="this.navCollapsed = !this.navCollapsed;"
             (onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container">
    <div class="pcoded-content"
         [ngClass]="{'container': this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'}">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
        <app-toast-mft-area></app-toast-mft-area>
    </div>
</div>
<app-configuration></app-configuration>
