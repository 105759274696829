<div class="modal-header">
    <h4 class="modal-title" *ngIf="isValid(title)">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"
            *ngIf="showCancelButton">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-template [ngIf]="askForAdminPsw">
        <p *ngIf="isValid(introTextForAdminPassword)" [innerHTML]="introTextForAdminPassword"></p>
        <input type="password" class="form-control" [(ngModel)]="adminPassword" placeholder="Inserisci la password">
        <span *ngIf="errorAdminPassword" class="error">Devi specificare la password</span>
        <hr>
    </ng-template>
    <p *ngIf="isValid(introText)" [innerHTML]="introText"></p>
    <ng-template [ngIf]="showInput">
        <input class="form-control" [type]="inputType" [step]="inputStep" [min]="inputMin" [max]="inputMax"
               [(ngModel)]="value" [autofocus]="true" [placeholder]="placeholder1">
        <span *ngIf="errorInput1" class="error">Devi Immettere un valore</span>
        <p *ngIf="isValid(introTextForInput2)" [innerHTML]="introTextForInput2"></p>
        <input *ngIf="useInput2" class="form-control" [type]="inputType2" [step]="inputStep2" [min]="inputMin2"
               [max]="inputMax2"
               [(ngModel)]="value2" [autofocus]="true" [placeholder]="placeholder2">
        <span *ngIf="errorInput2" class="error">Devi Immettere un valore</span>
        <span *ngIf="isValid(errorCustomFnText)" class="error" [innerHTML]="errorCustomFnText"></span>
    </ng-template>
</div>
<div class="modal-footer">
    <button *ngIf="showCancelButton" class="btn btn-outline-secondary" (click)="cancel()">{{cancelButtonText}}</button>

    <button class="btn btn-primary" (click)="confirm()"
            [disabled]="!isValid(value) && showInput">{{confirmButtonText}}</button>
</div>
