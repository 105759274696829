<div class="container"
     *ngIf="this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'; else mainHeader">
    <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
    <div class="m-header" style="width: 100%">
        <a href="javascript:" style="position: relative; left: 0;" class="mobile-menu" id="mobile-collapse"
           (click)="navCollapse()"><span></span></a>
        <a [routerLink]="['/dashboard']" class="b-brand">
            <img id="main-logo" src="assets/images/logo.png" alt="" class="logo">
            <img src="assets/images/logo-icon.png" alt="" class="logo-thumb">
        </a>
        <app-nav-right class="ml-auto"></app-nav-right>
        <!-- <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()"><i class="feather icon-more-vertical"></i></a> -->
    </div>
    <!--div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
      <app-nav-left class="mr-auto"></app-nav-left>
      <app-nav-right class="ml-auto"></app-nav-right>
    </div-->
</ng-template>
