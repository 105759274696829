<app-spinning-div *ngIf="!ready"></app-spinning-div>

<div class="table table-striped table-hover mb-0 notify-item-list" *ngIf="ready && items.length > 0">
    <div *ngFor="let item of items">
        <div class="notify-item" routerLink="{{item.link}}">
            <div class="caption">
                <span *ngIf="item.icon != null" class="badge m-r-5 badge-{{item.iconColor}}">
                    <i class="feather {{item.icon}}"></i>
                </span>
                <span *ngIf="item.icon2 != null" class="badge m-r-5 badge-{{item.icon2Color}}">
                    <i class="feather {{item.icon2}}"></i>
                </span>

                <h6>{{item.id}}</h6>
                <p class="text-muted  m-b-0">{{item.subTitle}}</p>
            </div>
            <div class="title" [innerHTML]="item.title"></div>
        </div>
        <div class="separator"></div>
    </div>
</div>
<ng-template [ngIf]="ready && items.length == 0">
    <div class="no-news">
        <p><i>Non sono presenti nuove notifiche</i></p>
    </div>
</ng-template>
<!--
<table class="table table-striped table-hover mb-0" *ngIf="ready">
    <tr *ngFor="let item of items" routerLink="{{item.link}}">
        <td>
            <div class="d-inline-block align-top">
                <div class="d-inline-block">
                    <span *ngIf="item.icon!=null" class="badge m-r-5 badge-{{item.iconColor}}" style="float:left"><i
                            class="feather {{item.icon}}"></i></span>
                    <span *ngIf="item.icon2!=null" class="badge m-r-5 badge-{{item.icon2Color}}" style="float:left"><i
                            class="feather {{item.icon2}}"></i></span>
                    <h6>{{item.id}}</h6>
                    <p class="text-muted  m-b-0">{{item.subTitle}}</p>
                </div>
            </div>
        </td>
        <td style="overflow-x: hidden">{{item.title}}</td>
    </tr>
</table>
-->
