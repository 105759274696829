import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-spinning-div',
    templateUrl: './spinning-div.component.html',
    styleUrls: ['./spinning-div.component.scss'],
})
export class SpinningDivComponent {
    @Input()
    extraClasses = 'col-12';

    constructor() {
    }
}
