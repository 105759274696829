<perfect-scrollbar *ngIf="this.nextConfig['layout'] === 'vertical'" (clickOutside)="navMob()"
                   [excludeBeforeClick]="true" [exclude]="'#mobile-collapse'" id="nav-ps-next" ngClass="able-pro-scroll"
                   [style.max-width]="'300px'" [style.max-height]="'calc(100vh - 60px)'" [usePSClass]="'next'"
                   [disabled]="null">
    <div class="navbar-content">
        <div class="">
            <div class="main-menu-header">
                <!--
                <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="User-Profile-Image">
                -->
                <i style="font-size: 40px" class="feather icon-user"></i>
                <div class="user-details" (click)="isNavProfile = !isNavProfile">
                    <div id="more-details">{{username}} <i class="fa fa-caret-down"
                                                           [ngClass]="{'fa-caret-up': isNavProfile }"></i></div>
                </div>
            </div>
            <div class="collapse" id="nav-user-link" *ngIf="isNavProfile" [style.display]="'block'">
                <ul class="list-unstyled">
                    <li class="list-group-item"><a href="javascript:" routerLink="/contatti"><i
                            class="fas fa-user m-r-5"></i>Profilo</a></li>

                    <ng-template [appIsClientGuard]>
                    <li *ngIf="!isTecnico" class="list-group-item"><a href="javascript:" (click)="changePassword()"><i
                            class="fas fa-key m-r-5"></i>Cambia password</a></li>
                    </ng-template>
                    <li class="list-group-item"><a routerLink="/informativa-privacy"><i
                            class="fas fa-sticky-note m-r-5"></i>Informativa privacy</a></li>
                    <li class="list-group-item"><a href="javascript:" (click)="logout()"><i
                            class="fas fa-sign-in-alt m-r-5"></i>Scollegati</a></li>
                </ul>
            </div>
        </div>
        <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
            <ng-container *ngFor="let item of navigation">
                <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
                <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
    </div>
</perfect-scrollbar>

<div *ngIf="this.nextConfig['layout'] === 'horizontal'" #navbarWrapper class="navbar-content sidenav-horizontal"
     id="layout-sidenav">
    <a href="javascript:" class="sidenav-horizontal-prev" [ngClass]="prevDisabled" (click)="scrollMinus()"></a>
    <div class="sidenav-horizontal-wrapper"><!-- add 14-4 viral -->
        <ul #navbarContent id="side-nav-horizontal" class="nav pcoded-inner-navbar sidenav-inner"
            (clickOutside)="fireLeave()" (mouseleave)="fireLeave()">
            <ng-container *ngFor="let item of navigation">
                <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
                <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
    </div>
    <a href="javascript:" class="sidenav-horizontal-next" [ngClass]="nextDisabled" (click)="scrollPlus()"></a>
</div>

<app-change-password *ngIf="profile != null" [forceChange]="!profile.changedPassword" #changePasswordComponent></app-change-password>
