import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-userful-numbers',
    templateUrl: './userful-numbers.component.html',
})
export class UserfulNumbersComponent {
    @Input()
    textCenter = true;

}
