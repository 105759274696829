export enum Role {
    CLIENT = 1,
    RESELLER = 10,
    COMMERCIAL_AGENT = 20
}

export interface User {
    username: string;
    token: string;
    role: Role;
}
