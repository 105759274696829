<div class="card same-height">
<!--<div class="container fullwidth" id="blog">-->
    <div class="card-body">

        <div class="span12">

            <p><strong>Informativa privacy</strong></p>
            <p><strong>Informativa ai sensi degli artt. 13-14 del RGPD (Regolamento Generale sulla Protezione dei Dati) &#8211; Regolamento UE 2016/679</strong></p>
            <p><strong>Premessa</strong><br />
                MFT ITALIA SRL UNIP. (in seguito MFT ITALIA), con sede in Ricengo (CR), via Offanengo, 7, in qualità di Titolare del Trattamento dei dati personali ai sensi del Regolamento UE 679/2016 – Regolamento Generale sulla Protezione dei Dati (&#8220;RGPD&#8221;) e della Normativa Nazionale applicabile (d’ora in avanti RGPD e Normativa Nazionale sono collettivamente indicati come &#8220;Normativa Applicabile&#8221;), riconosce l’importanza della protezione dei dati personali e considera la loro tutela uno degli obiettivi principali della propria attività.</p>
            <p>Prima di comunicare qualsiasi dato personale, MFT ITALIA la invita in qualità di utente a leggere con attenzione la presente Privacy Policy, poiché la stessa contiene informazioni importanti sulla tutela dei dati personali e sulle misure di sicurezza adottate per garantirne la riservatezza nel pieno rispetto della Normativa Applicabile.</p>
            <p>La presente Privacy Policy, inoltre:</p>
            <ul>
                <li>si intende resa solo per i siti riferiti a MFT ITALIA mentre non si applica ad altri siti web eventualmente consultati tramite link esterni;</li>
                <li>è da intendersi quale Informativa resa ai sensi della Normativa Applicabile a coloro che interagiscono con MFT ITALIA attraverso i siti, i nostri canali di assistenza e i nostri canali commerciali;</li>
                <li>si conforma alla Raccomandazione n. 2/2001 relativa ai requisiti minimi per la raccolta di dati on-line nell’Unione Europea, adottata il 17 maggio 2001 dal Gruppo di Lavoro Articolo 29.</li>
            </ul>
            <p>MFT ITALIA informa che il trattamento dei dati personali sarà improntato ai principi di liceità, correttezza, trasparenza, limitazione delle finalità e della conservazione, minimizzazione dei dati, esattezza, integrità e riservatezza. I dati personali verranno pertanto trattati in accordo alle disposizioni legislative della Normativa Applicabile e degli obblighi di riservatezza ivi previsti.</p>
            <p>&nbsp;</p>
            <p><strong>Titolar</strong><strong>e del trattamento</strong></p>
            <p>&nbsp;</p>
            <p>Il Titolare del trattamento è MFT ITALIA SRL UNIP. con sede legale in Ricengo (CR), &#8211; via Offanengo, 7.</p>
            <p>&nbsp;</p>
            <p><strong>I dati personali oggetto di trattamento</strong></p>
            <p>Per &#8220;Trattamento&#8221; di dati personali si intende qualsiasi operazione o insieme di operazioni, compiute con o senza l’ausilio di processi automatizzati e applicate ai dati personali o insieme di dati personali, come la raccolta, la registrazione, l’organizzazione, la strutturazione, la conservazione, l’adattamento o la modifica, l’ estrazione, la consultazione, l’uso, la comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a disposizione, il raffronto o l’interconnessione, la limitazione, la cancellazione o la distruzione.</p>
            <p>Per &#8220;Dati Personali&#8221; si intende qualsiasi informazione riguardante una persona fisica identificata o identificabile con particolare riferimento a un identificativo come il nome, un numero di identificazione, dati relativi all’ubicazione, un identificativo online o a uno o più elementi caratteristici della sua identità fisica, fisiologica, psichica, economica, culturale o sociale.</p>
            <p>I dati personali che raccogliamo possono dipendere da come interagisce con MFT ITALIA e possono essere raccolti anche se non è un cliente, talvolta possiamo riceverli da terze parti, qualora lei abbia dato loro il consenso a condividere le sue informazioni personali.</p>
            <p>In particolare, i Dati Personali raccolti sono i seguenti:</p>
            <p>&nbsp;</p>
            <p><strong>a. Dati raccolti automaticamente</strong></p>
            <p>I sistemi informatici e le procedure software preposte al funzionamento dei i siti riferiti a MFT ITALIA acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell&#8217;uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con altri dati, permettere di identificare gli utenti.</p>
            <p>In questa categoria di dati rientrano dati di navigazione quali ad esempio l’indirizzo di protocollo internet (IP) utilizzato per connettere ad internet il suo computer; le informazioni su computer e connessione quali tipo e versione di browser; gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l&#8217;orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, etc.); altri parametri relativi al sistema operativo e all&#8217;ambiente informatico dell&#8217;utente.</p>
            <p>I sistemi informatici e le procedure software, inoltre, generano identificativi di accesso quali i dati di accesso alla sua Area Cliente (login, indirizzo e-mail, password) e registrano i log di accesso all’Area Riservata e delle attività effettuate al suo interno.</p>
            <p>Tali dati vengono utilizzati al fine di adempiere all’obbligo, posto in carico al Titolare, di dimostrare la fondatezza e la validità dei consensi forniti dall’interessato e quali evidenza della disponibilità di alcuni dei diritti dello stesso.</p>
            <p>Per tutti gli altri casi sopra indicati, invece, i dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull&#8217;uso dei siti, per controllarne il corretto funzionamento, per permettere la corretta erogazione delle varie funzionalità richieste dall’utente, per ragioni di sicurezza e di accertamento di responsabilità in caso di reati informatici e vengono cancellati immediatamente dopo l&#8217;elaborazione.</p>
            <p>&nbsp;</p>
            <p><strong>b. Dati forniti volontariamente dall’utente</strong></p>
            <p>Dati anagrafici e di pagamento</p>
            <p>I dati forniti volontariamente sono i dati che vengono comunicati dall’utente, in relazione alla richiesta di informazioni o per l’acquisto di un servizio, direttamente o tramite i siti riferiti a MFT ITALIA, i nostri canali di assistenza, la partecipazione ad eventi o altre iniziative di MFT ITALIA.</p>
            <p>Fanno parte di questi dati, ad esempio, le sue generalità, l’indirizzo di fatturazione e quello di installazione, il codice fiscale, il numero di telefono, le informazioni sulla carta di credito (laddove venga selezionata come modalità di pagamento del servizio) o altre informazioni bancarie, l’indirizzo e-mail fornito in fase di richiesta del servizio.</p>
            <p>Questi dati sono essenziali per poterle fornire il servizio, ed un eventuale rifiuto al loro conferimento non consentirà a MFT ITALIA di ottemperare alle proprie obbligazioni contrattuali.</p>
            <p>MFT ITALIA tratterà questi dati nel rispetto della Normativa Applicabile, assumendo che siano riferiti all’utente o a terzi soggetti che lo hanno espressamente autorizzato a conferirli. Rispetto a tali ipotesi, l’utente si pone come autonomo Titolare del trattamento, assumendosi tutti gli obblighi e le responsabilità di legge. In tal senso, l’utente conferisce sul punto la più ampia manleva rispetto ad ogni contestazione, pretesa, richiesta di risarcimento del danno da trattamento, etc. che dovesse pervenire a MFT ITALIA da terzi soggetti i cui Dati Personali siano stati trattati attraverso un utilizzo effettuato dall’utente in violazione della Normativa Applicabile.</p>
            <p>c. Cookie e tecnologie affini</p>
            <p>MFT ITALIA raccoglie Dati Personali attraverso i cookie. Maggiori informazioni sull’uso dei cookie e tecnologie affini sono disponibili nella relativa informativa disponibile agli indirizzi <a href="http://www.mftitalia.it/normativa-cookies/">http://www.mftitalia.it/normativa-cookies/</a> <a href="http://www.volacolvento.it/cookie-policy/">http://www.volacolvento.it/cookie-policy/</a></p>
            <p>d. Altre tipologie di dato</p>
            <p>Raccogliamo informazioni anche:</p>
            <p>sul modo in cui utilizza i nostri Prodotti e Servizi, quali ad esempio: il livello di servizio ricevuto, come in caso di guasti di rete, di servizio e di altri eventi che possono influenzare i nostri servizi di rete o altri servizi.<br />
                sul suo account, quali le date di pagamento dovuto o ricevuto, il profilo del servizio in uso, le offerte sottoscritte o altre informazioni relative al suo account o incluse nella sua Area Riservata sui siti http://www.mftitalia.it/ e http://www.volacolvento.it/<br />
                MFT ITALIA ottiene informazioni attraverso l’accesso ai sistemi informativi di società autorizzate che prestano servizi per la gestione del rischio creditizio e da altri fornitori di dati che potrebbero includere dati demografici, dati basati sugli interessi e comportamento di navigazione su Internet.<br />
                Altri dati forniti volontariamente dall’utente attraverso l’invio di mail agli indirizzi di supporto disponibili sui siti o attraverso l’utilizzo del servizio chat.</p>
            <p><strong>Finalità, base giuridica e natura del trattamento</strong></p>
            <p>I Dati Personali forniti dall’utente saranno trattati da MFT ITALIA per le seguenti finalità:</p>
            <p><strong>a. Gestione degli ordini/contratti e fornitura di Prodotti e Servizi</strong></p>
            <p>i. Gestione degli ordini/contratti relativi ai Prodotti e dei Servizi che ha acquistato e aggiornamento sullo stato dell’ordine/contratto</p>
            <p>ii. Contatto dell’utente che ne ha fatto specifica richiesta per fornire informazioni e/o supporto nelle fasi che portano all’inserimento dell’ordine</p>
            <p><strong>b. Fatturazione/pagamenti e assistenza Clienti</strong></p>
            <p>i. Gestione dei pagamenti e della fatturazione per l’utilizzo dei nostri Prodotti o Servizi<br />
                ii. Risposta a qualsiasi domanda o segnalazione che si possa avere sulla nostra rete, sui Prodotti o sui Servizi</p>
            <p><strong>c. Messaggi di servizio</strong></p>
            <p>i. Per comunicazioni di servizio relative ai Prodotti e Servizi che ha con noi (per es. per comunicarle interruzioni del servizio per attività di manutenzione, comunicazioni relative ad eventuali disservizi o comunicazioni &#8220;istituzionali&#8221;)</p>
            <p><strong>d. Fornire servizi di interconnessione</strong></p>
            <p>i. Per garantire i servizi di interconnessione verso le reti degli altri operatori</p>
            <p><strong>e. Migliorare ed innovare i nostri Prodotti e Servizi</strong></p>
            <p>i. Effettuare sondaggi di opinione e verifica del livello di soddisfazione sui nostri servizi<br />
                ii. Raccogliere informazioni, anche acquisite da terze parti, anonime ed aggregate per migliorare i nostri Servizi e Prodotti che offriamo sul mercato. Nessuna di queste analisi consente di risalire individualmente al Cliente né viene effettuata per contattare i Clienti per scopi commerciali</p>
            <p><strong>f. Gestire le nostre reti e migliorarne l&#8217;utilizzo</strong></p>
            <p>i. Proteggere le nostre reti e gestire i volumi di chiamate, il traffico e altri usi delle nostre reti. Ad esempio, identifichiamo i periodi di utilizzo più elevati, in modo da poter garantire che le reti siano sempre in grado di gestire i volumi di traffico attesi</p>
            <p>ii. Capire come vengono utilizzate le nostre reti, in base ai diversi Prodotti e Servizi. Ciò ci consente di svilupparle e migliorarle e di sviluppare Prodotti e Servizi sempre più interessanti e rilevanti, nonché di personalizzarli</p>
            <p><strong>g. Marketing e personalizzazione dei servizi</strong></p>
            <p>i. Marketing e offerte personalizzate.</p>
            <p>I suoi dati personali potranno essere trattati, solo in presenza di specifici ed espressi consensi che MFT ITALIA le richiede: consenso commerciale e alla profilazione. Se deciderà di darci questi consensi potrà:</p>
            <ol>
                <li>&nbsp;tenersi sempre informato sui nuovi Prodotti e Servizi. L’invio di comunicazioni commerciali o di vendita diretta può avvenire secondo modalità automatizzate, tramite telefono, SMS, posta anche elettronica, banner web, messaggistica via internet e modalità analoghe. Tali comunicazioni possono essere anche funzionali a iniziative ed offerte nonché a programmi e promozioni, manifestazioni a premio, volte a premiare i Clienti; possono prevedere l’invito ad eventi e la partecipazione a sondaggi (potrà sempre revocare questo consenso e scegliere di non ricevere i nostri messaggi di marketing &#8211; per ulteriori dettagli su come revocare il consenso, veda la specifica sezione che segue). Tali comunicazioni potranno riguardare: Prodotti e Servizi di MFT ITALIA e di Partner commerciali con cui MFT ITALIA abbia stipulato accordi commerciali, ricerche di mercato e sondaggi.</li>
                <li>&nbsp;ricevere informazioni anche su Prodotti e Servizi di altre società che riteniamo possano interessarle, sempre che abbia dato uno specifico consenso a ricevere queste informazioni ed a meno che lo stesso non sia stato revocato.</li>
                <li>&nbsp;ricevere offerte con promozioni e sconti personalizzati sulla base di come vengono utilizzati i nostri Prodotti e Servizi, delle sue abitudini e propensioni al consumo e della sua appartenenza a specifiche tipologie di famiglie e/o gruppi di clienti.</li>
            </ol>
            <p>Potrà tenersi informato, ricevere informazioni sui nuovi prodotti e servizi e ricevere offerte personalizzate anche qualora abbia fornito specifico consenso ad altre società con cui MFT ITALIA collabora.</p>
            <p>ii. Advertising online</p>
            <p>Utilizziamo e consentiamo ad altre società selezionate di utilizzare cookie e altre tecnologie simili allo scopo di comprendere l&#8217;utilizzo dei nostri Servizi da parte dell&#8217;utente; migliorare l&#8217;esperienza utente e abilitare funzionalità e contenuti personalizzati; ottimizzare pubblicità e marketing; consentire ad agenzie pubblicitarie di assisterci nella presentazione di pubblicità personalizzata.</p>
            <p>Se desidera che nessuna informazione venga elaborata attraverso l&#8217;uso dei cookie, consulti l’Informativa Cookie in cui viene spiegato come controllarli e disattivarli. Le ricordiamo che l&#8217;esclusione dalla pubblicità ad-hoc non impedirà la visualizzazione degli annunci pubblicitari, che non saranno basati sui suoi interessi.</p>
            <p>Le sopraindicate società selezionate che utilizzano cookie e altre tecnologie simili operano come distinti Titolari del trattamento o in qualità di Responsabili o Incaricati di tale trattamento e garantiscono di non utilizzare alcun dato ricevuto da MFT ITALIA per altri scopi.</p>
            <p><strong>h. Prevenzione di frodi</strong></p>
            <p>i. Per proteggere e rilevare abusi o danneggiamenti alle nostre reti.</p>
            <p>La base giuridica del trattamento di Dati Personali per le finalità di cui ai punti a), b), c), d) è l’esecuzione del contratto, ovvero l’erogazione di un servizio o il riscontro ad una richiesta esplicita dell’utente.<br />
                La base giuridica per le finalità di cui ai punti e), f), h) è un legittimo interesse del Titolare, mentre per la finalità g) la base giuridica è rappresentata dal consenso fornito dal soggetto interessato.</p>
            <p>Il trattamento dei dati avverrà mediante strumenti idonei a garantire la sicurezza nonché la riservatezza e potrà essere effettuato anche mediante strumenti automatizzati atti a memorizzare, gestire e trasmettere i dati stessi.</p>
            <p><strong>Destinatari</strong><br />
                Il trattamento dei dati da lei fornitici, o altrimenti acquisiti nell&#8217;ambito della nostra attività, verrà effettuato soltanto da soggetti previamente autorizzati, nominati incaricati o responsabili da MFT ITALIA, quali i dipendenti di MFT ITALIA o di società esterne che collaborino con MFT ITALIA al solo fine di fornirle il servizio richiesto (a titolo esemplificativo, l’installatore che si occuperà di installare l’antenna presso la sua abitazione).</p>
            <p>In alcuni casi i suoi dati potranno essere forniti a soggetti a cui sia riconosciuta la facoltà di accedere agli stessi dalla normativa italiana o comunitaria.</p>
            <p>Nel caso in cui lei abbia acquistato Prodotti e Servizi MFT ITALIA utilizzando una terza parte o un partner MFT ITALIA, potrebbe essere necessario scambiare informazioni con questi soggetti, ad esempio per poter identificare il suo ordine e poter pagare il servizio direttamente a questa terza parte.</p>
            <p>Se abbiamo un contratto con un fornitore terzo che offre servizi per nostro conto e che può avere accesso ai suoi dati personali, tale soggetto può trattare i suoi dati personali, solo per fornirle i servizi richiesti. Tali fornitori sono nominati Responsabili del trattamento dei dati personali ai sensi della Normativa Applicabile e hanno sottoscritto con MFT ITALIA uno specifico accordo sul trattamento dei dati personali.</p>
            <p>I Dati Personali conferiti dall’utente potranno essere condivisi, per le finalità specificate al punto precedente, con:</p>
            <p>a. partner o agenti incaricati nella vendita, consegna e installazione dei prodotti e servizi che ha ordinato o utilizzato;</p>
            <p>b. società appartenenti alla rete distributiva di MFT ITALIA, diretta ed indiretta;</p>
            <p>c. società e Studi Legali che prestano servizi per la gestione del rischio del credito;</p>
            <p>d. forze dell’ordine, enti governativi, organismi di regolamentazione, autorità giudiziarie o altre autorità pubbliche autorizzate dalla legge;</p>
            <p>e. una terza parte o un organismo cui tale divulgazione sia richiesta per soddisfare qualsiasi legge applicabile o altro requisito legale o normativo;</p>
            <p>f. partner commerciali in caso di lancio di promozioni/offerte;</p>
            <p>g. società che effettuano per conto di MFT ITALIA servizi di acquisizione, lavorazione ed elaborazione dei dati necessari per la fruizione dei servizi per la Clientela;</p>
            <p>h. società che forniscono a MFT ITALIA servizi per la gestione del sistema informativo di MFT ITALIA;</p>
            <p>i. studi e società nell’ambito dei rapporti di assistenza e consulenza;</p>
            <p>j. società che effettuano ricerche di mercato volte a rilevare il grado di soddisfazione della Clientela;</p>
            <p>k. società che svolgono adempimenti di controllo, revisione e certificazione delle attività poste in essere da MFT ITALIA anche nell’interesse dei propri Clienti e utenti;</p>
            <p>l. istituti Bancari e società emittenti carte di credito;</p>
            <p>m. altri operatori di telecomunicazioni, per la gestione dei rapporti di interconnessione.</p>
            <p>&nbsp;</p>
            <p><strong>Trasferimenti</strong><br />
                Alcuni dei Dati Personali conferiti dall’utente possono essere trasferiti a Destinatari che si potrebbero trovare al di fuori dello Spazio Economico Europeo. MFT ITALIA assicura che in tale caso il trattamento elettronico e cartaceo dei Dati Personali da parte dei Destinatari avviene nel rispetto della Normativa Applicabile. In tali casi, i trasferimenti si basano alternativamente su una decisione di adeguatezza o sulle Standard Model Clauses approvate dalla Commissione Europea. Maggiori informazioni e copia di questi accordi sono reperibili facendone richiesta al DPO.</p>
            <p>&nbsp;</p>
            <p><strong>Conservazione dei dati</strong></p>
            <p>I dati saranno conservati sia nella forma cartacea che digitale, presso la nostra sede operativa, sita in Via Cascina Secchi,351 – 24040 ISSO (BG), per i tempi prescritti dalle norme di legge vigenti.</p>
            <p>MFT ITALIA tratterà i Dati Personali per il tempo strettamente necessario a raggiungere gli scopi indicati dalle diverse finalità del trattamento. Fatto salvo quanto sopra, MFT ITALIA tratterà i Dati Personali fino al tempo permesso dalla legge Italiana a tutela dei propri interessi (Art. 2947(1)(3) c.c.). Maggiori informazioni in merito al periodo di conservazione dei Dati Personali e ai criteri utilizzati per determinare tale periodo possono essere richieste scrivendo al DPO.</p>
            <p>&nbsp;</p>
            <p><strong>D</strong><strong>iritti degli interessati</strong></p>
            <p>&nbsp;</p>
            <p>Nei limiti della Normativa Applicabile, l’utente ha il diritto di chiedere ad MFT ITALIA, in qualunque momento, l’accesso ai propri Dati Personali, la rettifica o la cancellazione degli stessi o di opporsi al loro trattamento, la limitazione del trattamento nonché di ottenere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati che lo riguardano.<br />
                Le richieste vanno rivolte via e-mail all’indirizzo: <a href="mailto:privacy@mftitalia.it">privacy@mftitalia.it</a></p>
            <p>I consensi e le impostazioni privacy sono sempre modificabili tramite apposita sezione all’interno della sua Area Riservata all’interno dei siti <a href="http://www.mftitalia.it/">http://www.mftitalia.it/</a> e <a href="http://www.volacolvento.it/">http://www.volacolvento.it/ </a>Le ricordiamo che, a seguito della revoca del consenso precedentemente prestato, la stessa sarà immediatamente recepita ma definitivamente operativa a seguito del tempo tecnico necessario per l’allineamento tra tutti i sistemi informativi da noi utilizzati.</p>
            <p>È inoltre sempre possibile modificare i consensi prestati e le impostazioni privacy, anche dopo la conclusione del rapporto contrattuale, accedendo alla propria Area Riservata all’interno dei siti <a href="http://www.mftitalia.it/">http://www.mftitalia.it/ </a>e <a href="http://www.volacolvento.it/">http://www.volacolvento.it/</a></p>
            <p>In caso di revoca del consenso commerciale, continuerà a ricevere solamente i Messaggi di servizio di MFT ITALIA. Al contrario, in assenza di espressa revoca, il consenso prestato verrà utilizzato da MFT ITALIA. per tale finalità anche dopo la cessazione del servizio/del rapporto contrattuale, fatta salva in ogni caso la Sua facoltà di revocare in ogni momento il consenso precedentemente prestato.</p>
            <p>Ai sensi della Normativa Applicabile, l’utente ha in ogni caso il diritto di proporre reclamo all’Autorità di Controllo competente (Garante per la Protezione dei Dati Personali) qualora ritenesse che il trattamento dei suoi Dati Personali sia contrario alla normativa vigente.</p>
            <p><strong>Modifiche</strong><br />
                La presente Informativa Privacy è in vigore dal 25/05/2018. MFT ITALIA si riserva di modificarne o semplicemente aggiornarne il contenuto, in parte o completamente, anche a causa di variazioni della Normativa Applicabile. MFT ITALIA informerà l’utente di tali variazioni non appena verranno introdotte; le stesse saranno vincolanti non appena pubblicate sul Sito: si invita quindi a visitare con regolarità questa sezione per avere conoscenza della più recente ed aggiornata versione della Privacy Policy ed in modo che l’utente sia sempre aggiornato sui dati raccolti e sull’uso che MFT ITALIA ne fa.</p>
        </div>

    </div>
</div>
