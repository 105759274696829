import {Injectable} from '@angular/core';
import {getModuleState, ModuleState, ProfileResponse} from '../../../../models/responses/profile-data';
import {AuthService} from '../../../../shared/auth.service';
import {ClientRole} from '../../../../models/responses/login-data';

export interface NavigationItem {
    id: string;
    title: string;
    type: 'item' | 'collapse' | 'group';
    translate?: string;
    icon?: string;
    hidden?: boolean;
    url?: string;
    classes?: string;
    exactMatch?: boolean;
    external?: boolean;
    target?: boolean;
    breadcrumbs?: boolean;
    function?: any;
    onlyClient: boolean;
    badge?: {
        title?: string;
        type?: string;
    };
    children?: Navigation[];
}

export interface Navigation extends NavigationItem {
    children?: NavigationItem[];
}

const MFTNavigationItems = [
    {
        id: 'navigation',
        title: 'Menu',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
            {
                id: 'dashboard',
                title: 'Area Cliente',
                type: 'item',
                url: '/dashboard',
                icon: 'feather icon-home',
                classes: 'nav-item'
            },
            {
                id: 'ticket',
                title: 'Area Ticket',
                type: 'item',
                url: '/ticket',
                icon: 'feather icon-message-square',
                classes: 'nav-item',
                children: [
                    {
                        id: 'ticket-detail',
                        title: 'Dettagli Ticket',
                        type: 'regex',
                        url: '/ticket/\\d+',
                        icon: 'feather icon-message-square',
                        classes: 'nav-item'
                    },
                    {
                        id: 'ticket-new',
                        title: 'Nuovo Ticket',
                        type: 'regex',
                        url: '/ticket/new/[a-zA-Z0-9-_.@]+',
                        icon: 'feather icon-message-square',
                        classes: 'nav-item',
                    }
                ]
            },
            {
                id: 'invoices',
                title: 'Fatture',
                type: 'item',
                url: '/invoices',
                icon: 'feather icon-file-text',
                classes: 'nav-item',
            },
            {
                id: 'account',
                title: 'Impostazioni Account',
                type: 'collapse',
                icon: 'feather icon-user',
                children: [
                    {
                        id: 'premium',
                        title: 'Assistenza Premium',
                        type: 'item',
                        url: '/premium',
                        icon: 'feather icon-star',
                        classes: 'nav-item',
                        onlyClient: true
                    },
                    {
                        id: 'tecnici',
                        title: 'Account Tecnici',
                        type: 'item',
                        url: '/tecnici',
                        icon: 'feather icon-users',
                        classes: 'nav-item',
                        onlyClient: true
                    },
                    {
                        id: 'contatti',
                        title: 'Contatti',
                        type: 'item',
                        url: '/contatti',
                        icon: 'feather icon-book',
                        classes: 'nav-item',
                    }
                ]
            },
            {
                id: 'speed-test',
                title: 'Speed Test',
                type: 'item',
                url: '/speedtest',
                icon: 'feather icon-activity',
                classes: 'nav-item',
                onlyClient: true
            },
            /*
            {
                id: 'docs-area',
                title: 'Documenti',
                type: 'item',
                url: '/docs',
                icon: 'feather icon-info',
                classes: 'nav-item',
            }
            */
        ],
    }
];


@Injectable()
export class NavigationItem {

    constructor(private auth: AuthService) {
    }

    public get(profile: ProfileResponse = null) {
        if (profile != null) {

            // clona la struttura di MFTNavigationItems
            const nav = [];
            nav[0] = {...MFTNavigationItems[0]};

            // si prepara per ricreare "children"
            const menuItems = nav[0].children;
            nav[0].children = []; // lo svuota

            for (const menuItem of menuItems) {
                const state = getModuleState(menuItem.id, profile.modules, ModuleState.ENABLED);

                // TODO: questa parte andrebbe rifatta con i permessi ottenuti dalle API
                if (state > ModuleState.NOT_VISIBLE && (!menuItem.onlyClient || this.auth.hasAnyRole([ClientRole.ROLE_CLIENT, ClientRole.ROLE_MFT]))) {

                    menuItem.children = menuItem.children?.filter(
                        child => !child.onlyClient || this.auth.hasAnyRole([ClientRole.ROLE_CLIENT, ClientRole.ROLE_MFT])
                    ) ?? [];

                    nav[0].children.push(menuItem);
                }
            }
            return nav;
        }
        return MFTNavigationItems;
    }
}
