import {Component, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@Component({
    selector: 'app-speed-test',
    templateUrl: './speed-test.component.html',
    styleUrls: ['./speed-test.component.scss']
})
export class SpeedTestComponent {
    constructor(private sanitizer: DomSanitizer) {
    }
}
