import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {RestApiService} from './rest-api.service';
import {Response} from 'ts-retrofit';
import {GenericResponse} from './generic-response';
import {ClientRole, LoginData} from '../models/responses/login-data';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {HttpClient} from "@angular/common/http";

export interface ISignInCredentials {
    username: string;
    password: string;
}

export interface ICreateCredentials {
    email: string;
    password: string;
    displayName: string;
}

export interface IPasswordReset {
    code: string;
    newPassword: string;
}


@Injectable({providedIn: 'root'})
export class AuthService {

    loginPath: string;

    constructor(private api: RestApiService, private storage: StorageService,
                private router: Router, private http: HttpClient) {
    }

    // signIn(credentials: ISignInCredentials): Observable<auth.UserCredential> {
    signIn(credentials: ISignInCredentials): Observable<Response<GenericResponse<LoginData>>> {
        const promise = this.api.doLogin(credentials.username, credentials.password);
        promise.then(value => {
            if (value?.data?.bSuccess && value?.data?.oData != null) {
                console.log('token ottenuto: ' + value.data.sToken);
                this.storage.loginData = value.data.oData;
                this.storage.token = value.data.sToken;
            } else {
                this.storage.loginData = null;
                this.storage.token = null;
            }
        });
        return from(promise);
        // return from(this.afAuth.auth.signInWithEmailAndPassword(credentials.email, credentials.password));
    }

    signInHash(hash: string): Observable<Response<GenericResponse<LoginData>>> {

        const promise = this.api.doLoginHash(hash);
        promise.then(value => {
            if (value?.data?.bSuccess && value?.data?.oData != null) {
                console.log('token ottenuto: ' + value.data.sToken);
                this.storage.loginData = value.data.oData;
                this.storage.token = value.data.sToken;
            } else {
                this.storage.loginData = null;
                this.storage.token = null;
            }
        });
        return from(promise);
        // return from(this.afAuth.auth.signInWithEmailAndPassword(credentials.email, credentials.password));
    }

    setLoginPath(url: string) {
        this.loginPath = url;
    }

    getLoginPath(): string {
        if (this.loginPath != null && this.loginPath.length > 0) {
            const path = this.loginPath;
            this.loginPath = null;
            return path;
        }
        return '/';
    }

    signOut(): Observable<Response<GenericResponse<any>>> {
        this.storage.token = null;
        this.storage.loginData = null;
        return from(this.api.doLogout());
    }

    get loginData(): LoginData {
        return this.storage.loginData;
    }

    /*
      get currentIDAzienda(): number {
        return this.storage.loginData?.oLocation?.IDAzienda;
      }

      get currentLocation(): Location {
        return this.storage.loginData?.oLocation;
      }

      get currentUser(): User {
        return this.storage.loginData?.oUser;
      }*/

    updateLoginData(loginResponse: LoginData) {
        this.storage.loginData = loginResponse;
        this.updateLanguage();
        this.router.navigate([this.getLoginPath()]);
    }

    private updateLanguage() {
        const languageCode = 'IT-IT'; // this.storage.loginData.oUser.sLang;
        console.log('default language: ' + languageCode);
        // this.langService.language = languageCode;
    }

    isLoggedIn(): boolean {
        return this.storage.loginData != null;
    }

    getToken(): string {
        return this.storage.token;
    }

    hasRole(role: ClientRole) {
        const clientRole = this.storage.loginData.clientRole ?? ClientRole.ROLE_CLIENT;
        return clientRole === role;
    }

    hasAnyRole(roles: ClientRole[]) {
        const clientRole = this.storage.loginData.clientRole ?? ClientRole.ROLE_CLIENT;
        return roles.includes(clientRole);
    }

}
