import {
    GET, POST, PUT, PATCH, DELETE, HEAD, OPTIONS, BasePath, Header, Query,
    Headers, Path, Field,
    BaseService, ServiceBuilder, Response, ResponseInterceptor, Body
} from 'ts-retrofit';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {GenericResponse} from './generic-response';
import {LoginData} from '../models/responses/login-data';
import {AuthService} from './auth.service';
import {StorageService} from './storage.service';
import {ProfileResponse} from '../models/responses/profile-data';

export const ACCEPT_JSON = {Accept: 'application/json'};
export const URL_ENCODED = {'content-type': 'application/x-www-form-urlencoded'};
export const CONTENT_TYPE_JSON = {'Content-Type': 'application/json'};
const TOKEN_HEADER = 'Token';

@BasePath(environment.apiUrl + environment.basePrefixApiUrl)
export class RestApiService extends BaseService {
    // ========================================================================================
    //          LOGIN
    // ========================================================================================
    @POST('/login')
    @Headers({
        ...URL_ENCODED,
        ...ACCEPT_JSON
    })
    async doLogin(@Field('user') username: string,
                  @Field('pass') password: string): Promise<Response<GenericResponse<LoginData>>> {
        return {} as Response<GenericResponse<LoginData>>;
    }

    // ========================================================================================
    //          LOGOUT
    // ========================================================================================
    @POST('/logout')
    @Headers({
        ...URL_ENCODED,
        ...ACCEPT_JSON
    })
    async doLogout(): Promise<Response<GenericResponse<any>>> {
        return {} as Response<GenericResponse<any>>;
    }

    // ========================================================================================
    //          PROFILE
    // ========================================================================================
    @GET('/profile/0')
    @Headers({
        ...ACCEPT_JSON
    })
    async doProfile(): Promise<Response<GenericResponse<ProfileResponse[]>>> {
        return {} as Response<GenericResponse<ProfileResponse[]>>;
    }

    // ========================================================================================
    //          CHANGE PASSWORD
    // ========================================================================================
    @POST('/profile/0/changePassword')
    @Headers({
        ...URL_ENCODED,
        ...ACCEPT_JSON
    })
    async doChangeAdminPassword(@Field('psw') psw: string): Promise<Response<GenericResponse<boolean>>> {
        return {} as Response;
    }

    // ========================================================================================
    //          RECOVER CREDENTIALS
    // ========================================================================================
    @POST('/recoverCredentials')
    @Headers({
        ...URL_ENCODED,
        ...ACCEPT_JSON
    })
    async recoverCredentials(@Field('email') email: string): Promise<Response<GenericResponse<boolean>>> {
        return {} as Response;
    }

    // ========================================================================================
    //          RESET CREDENTIALS
    // ========================================================================================
    @POST('/recoverCredentials/reset')
    @Headers({
        ...URL_ENCODED,
        ...ACCEPT_JSON
    })
    async resetCredentials(@Field('recover') recover: string, @Field('psw') psw: string)
    : Promise<Response<GenericResponse<boolean>>> {
        return{} as Response;
    }

    @POST('/loginhash')
    @Headers({
        ...URL_ENCODED,
        ...ACCEPT_JSON
    })
    async doLoginHash(@Field('hash')hash: string):Promise<Response<GenericResponse<LoginData>>> {
        return {} as Response<GenericResponse<LoginData>>;
    }
}

export class MftResponseInterceptor extends ResponseInterceptor<any> {
    onFulfilled(response) {
        if (response === null || response.data === null) {
            console.log(response);
            // throw new RestApiError('errore generico');
        }

        // Tutte le risposte hanno solitamente codice 200, anche quando presentano errori.
        // Se ce ne arriva una che ha un codice diverso da 200 lascio che venga gestita nel modo appropriato.
        if (response.status !== 200) { return response; }

        // Se la richiesta ha codice 200 controllo se ha errore o meno
        if (!(response.data?.bSuccess ?? false)) {
            throw new RestApiError(response.data.sError, response.data.sInnerError);
        }
        return response;
    }
    onRejected(error: any) {
        super.onRejected(error);
        throw error;
    }
}

export function getRestApiServiceBuilder(storageService: StorageService, useInterceptor = true): ServiceBuilder {
    const responseInterceptor = new MftResponseInterceptor();

    const serviceBuilder = new ServiceBuilder()
        .setRequestInterceptors((request) => {
            const url = request.url.substr((environment.apiUrl + environment.basePrefixApiUrl).length);
            if (storageService.token != null) {
                console.log('token: ' + storageService.token);
                request.headers[TOKEN_HEADER] = storageService.token;
            }
            return request;
        });
    if (useInterceptor === true) {
        serviceBuilder.setResponseInterceptors(responseInterceptor);
    }

    return serviceBuilder;

}

export function createRestApiService(storageService: StorageService): RestApiService {
    return getRestApiServiceBuilder(storageService).build(RestApiService);
}

// ===================================================================================
//    ERROR MANAGER
// ===================================================================================
/*
declare global {c
    interface Error {
        name: string;
        message: string;
        stack?: string;
    }
}
*/

// TODO: non capisco perche` non viene intercettata l'eccezione
export class RestApiError extends Error {
    public innerError: string;

    public getMessage() {
        return this.message + ': ' + (this.innerError);
    }

    constructor(message: string, innerMessage?: string) {
        super(message);
        this.name = 'RestApiError';
        this.message = message;
        this.innerError = innerMessage;
    }
}
