<div class="text-center">
    <div class="saprator my-4"><span>NUMERI UTILI</span></div>

    <table class="text-left col-12">
        <tr>
            <td colspan="2" class="text-muted"  [class.text-center]="textCenter">
                Assistenza commerciale tecnica amministrativa per linea e accessori (router etc.) *
            </td>
        </tr>
        <tr>
            <td style="color:orange">STANDARD</td>
            <td><a href="tel:03631922409">📞 0363.192.2409</a></td>
        </tr>
        <tr>
            <td style="color:green">PREMIUM</td>
            <td><a href="tel:0373386118">📞 0373.386.118</a></td>
        </tr>
        <tr style="height:10px">
            <td colspan="2">&nbsp;</td>
        </tr>
        <tr class="mt-2">
            <td colspan="2" class="text-muted" [class.text-center]="textCenter">
                Info commerciali sull'attivazione di nuovi servizi: Fibra/ADSL/HDSL/RADIO o altro
            </td>
        </tr>
        <tr>
            <td>Centralino</td>
            <td><a href="tel:0373736363">📞 0373.736.363</a></td>
        </tr>
        <tr style="height:10px">
            <td colspan="2">&nbsp;</td>
        </tr>
        <tr class="mt-2">
            <td colspan="2" class="text-muted" [class.text-center]="textCenter">
                Assistenza sistemistica per aziende con nostre forniture informatiche o con servizi di assistenza in abbonamento
            </td>
        </tr>
        <tr>
            <td>Centralino</td>
            <td><a href="tel:0373386118">📞 0373.386.118</a></td>
        </tr>
    </table>
</div>
