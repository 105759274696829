import {Router} from '@angular/router';
import {EventEmitter} from '@angular/core';
import {ProfileModule, ProfileResponse} from '../models/responses/profile-data';
import {RestApiService} from './rest-api.service';
import {Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {Configs} from '../../configs';

export class NotificationService {
    // ===================================================================================
    //    PROPS
    // ===================================================================================

    private _profile: ProfileResponse;

    private _cachedTimeStamp?: Date = null;

    private eventEmitter = new EventEmitter<ProfileResponse>();

    // ===================================================================================
    //    CONSTRUCTOR
    // ===================================================================================
    constructor(private api: RestApiService, private authService: AuthService, private router: Router) {
    }

    // ===================================================================================
    //    STATIC METHODS
    // ===================================================================================

    // ===================================================================================
    //    METHODS
    // ===================================================================================

    subscribe(func: (profile: ProfileResponse) => void): Subscription {
        return this.eventEmitter.subscribe(func);
    }

    async getNotifications(useCache = false): Promise<ProfileResponse> {
        if (useCache && this.isCacheValid()) {
            this.eventEmitter.emit(this._profile);
            return this._profile;
        }

        console.log('>>> Ricarico profilo @' + new Date());
        const response = await this.api.doProfile();
        if (response?.data?.oData == null) {
            console.log('profilo vuoto');

            this.authService.signOut();
            return null;
        }
        console.log('caricamento profilo terminato');
        this._cachedTimeStamp = new Date();
        this._profile = response.data.oData[0];
        this.updateModulesIds();
        this.eventEmitter.emit(this._profile);
        return this._profile;
    }

    clearCache() {
        this._profile = null;
        this._cachedTimeStamp = null;
    }

    // ===================================================================================
    //    PRIVATE METHODS
    // ===================================================================================

    /**
     * Fa in modo che i moduli siano accessibili tramite l'array modules tramite id associativo.
     * Esempio: profile.modules["ticket"]
     */
    private updateModulesIds() {
        const modules = [];
        for (const mod of this._profile.modules) {
            // const tmp: ProfileModule = {id: mod.id, visible: mod.visible, enabled: mod.enabled};
            modules[mod.id] = mod;
        }
        this._profile.modules = modules;
    }

    private isCacheValid() {
        // controlla che vi siano i dati in cache
        if (this._profile == null) {
            return false;
        }
        // controlla che la cache sia ancora valida
        if (this._cachedTimeStamp != null) {
            const maxDeltaTime = environment.cacheTimeoutMinutes * 60 * 1000;
            const now = new Date();
            const delta = now.getTime() - this._cachedTimeStamp.getTime();
            if (delta >= maxDeltaTime) {
                return false;
            }
        }
        return true;
    }

    // ===================================================================================
    //    HELPER METHODS
    // ===================================================================================
}
