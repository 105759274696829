import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler, NgModule, NgZone} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './theme/shared/shared.module';

import {AppComponent} from './app.component';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {NavigationComponent} from './theme/layout/admin/navigation/navigation.component';
import {NavContentComponent} from './theme/layout/admin/navigation/nav-content/nav-content.component';
import {NavGroupComponent} from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import {NavCollapseComponent} from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import {NavItemComponent} from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import {NavBarComponent} from './theme/layout/admin/nav-bar/nav-bar.component';
import {NavLeftComponent} from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import {NavSearchComponent} from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import {NavRightComponent} from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ConfigurationComponent} from './theme/layout/admin/configuration/configuration.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ToggleFullScreenDirective} from './theme/shared/full-screen/toggle-full-screen';

/* Menu Items */
import {NavigationItem} from './theme/layout/admin/navigation/navigation';
import {NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {createRestApiService, RestApiService} from './shared/rest-api.service';
import {StorageService} from './shared/storage.service';
import {Router} from '@angular/router';
import {AuthService} from './shared/auth.service';
import {ToastMftService} from './theme/shared/components/toast/toast-mft.service';
import {NotificationService} from './shared/notification-service';
import {MftExceptionHandler} from './shared/mft-exception.handler';
import {SafePipe, SpeedTestComponent} from './view/speed-test/speed-test.component';
import {DocsAreaComponent} from './view/docs-area/docs-area.component';
import {InfoPrivacyComponent} from "./view/privacy/info-privacy.component";

@NgModule({
    declarations: [
        AppComponent,
        AdminComponent,
        AuthComponent,
        NavigationComponent,
        NavContentComponent,
        NavGroupComponent,
        NavCollapseComponent,
        NavItemComponent,
        NavBarComponent,
        NavLeftComponent,
        NavSearchComponent,
        NavRightComponent,
        ConfigurationComponent,
        ToggleFullScreenDirective,
        SafePipe,
        SpeedTestComponent,
        DocsAreaComponent,
        InfoPrivacyComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbButtonsModule,
        NgbTabsetModule,
        HttpClientModule,
    ],
    providers: [
        {provide: RestApiService, useFactory: createRestApiService, deps: [StorageService]},
        {provide: StorageService, useClass: StorageService, deps: [Router]},
        {provide: NotificationService, deps: [RestApiService, AuthService, Router]},
        {provide: NavigationItem, deps: [AuthService]},
        // lasciare sempre per ultimo
        {provide: ErrorHandler, useClass: MftExceptionHandler, deps: [ToastMftService, Router, NgZone]},
    ],

    bootstrap: [AppComponent]
})
export class AppModule {
}
