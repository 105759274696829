import {ToastMftService} from '../theme/shared/components/toast/toast-mft.service';
import {RestApiError} from './rest-api.service';
import {ErrorHandler, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {Configs} from '../../configs';
import http from 'axios';


export class MftExceptionHandler implements ErrorHandler {
    constructor(private toastMftService: ToastMftService, private router: Router, private zone: NgZone) {
    }

    handleError(error: any): void {
        if (error === null) {
            console.log('Ho catturato una eccezione vuota');
            return;
        }

        if (error.rejection !== undefined && error.rejection instanceof RestApiError) {
            if (error.rejection.message === 'Anonymous access prohibited') {
                console.log('Navigate to login');
                this.zone.run(() => {
                    window.location.href = window.location.pathname !== Configs.loginPath ?
                        Configs.loginPath + '?url=' + encodeURI(window.location.pathname) :
                        Configs.loginPath;
                });
                return;
            }
        }

        const emitGenericError = () => {
            console.log('il seguente errore potrebbe essere stato comunque gestito altrove: ');
            console.log(error); // todo: creare logger degli errori non gestiti?
            // this.toastMftService.emitToast({
            //     title: 'Errore generico',
            //     body: 'Si &egrave; verificato un errore imprevisto.<br>Se il problema persiste, contattaci',
            //     timeout: 5000,
            //     caption: ''
            // });
        };

        const emitNetworkError = () => {
            this.toastMftService.emitToast({
                title: 'Problemi di connessione',
                body: 'Non si è connessi alla rete internet.<br>Se il problema persiste, contattaci',
                timeout: 5000,
                caption: ''
            });
        };

        // Test connessione

        const ping = () =>
            http.get('https://mft-ws.mftitalia.it/api/ping')
                .then(emitGenericError)
                .catch(emitNetworkError); // altrimenti c'è un problema di rete

        this.zone.run(() => {
            ping();
        });
    }
}
