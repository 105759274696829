import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RestApiService} from '../../../../shared/rest-api.service';
import {InputModalComponent} from '../input-modal/input-modal.component';
import {containsEmoji} from '../../../../shared/string-functions';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

    // =======================================================================
    //      FIELDS
    // =======================================================================

    @Input()
    forceChange = false;

    // =======================================================================
    //      CONSTRUCTOR
    // =======================================================================

    constructor(private modalService: NgbModal, private api: RestApiService) {
    }

    ngOnInit() {
        if (this.forceChange) {
            this.changePassword(true);
        }
    }

    // =======================================================================
    //      METHODS
    // =======================================================================

    public changePassword(firstChange: boolean = false) {
        const customIntroText = firstChange
            ? 'Al tuo primo accesso devi cambiare la password.<br>Inserisci una nuova password'
            : 'Inserisci una nuova password';
        InputModalComponent.promptInput(this.modalService, (values) => {
            InputModalComponent.showAlert(this.modalService, 'Modifica apportata',
                'La password &egrave; stata aggiornata. A partire dal prossimo accesso dovrai accedere ' +
                'con le nuove credenziali.');
        }, {
            title: 'Cambio password',
            introText: customIntroText,
            useInput2: true,
            showCancelButton: !firstChange,
            introTextForInput2: 'Ripeti la password',
            customCheckFn: async (values) => {
                const errors = checkGoodPassword(values.value);
                if (errors.length > 0) {
                    const implodedErrors = errors.join('</li><li>');
                    return {result: false, errorText: `<ul><li>${implodedErrors}</li></ul>`};
                }
                if (values.value !== values.value2) {
                    return {result: false, errorText: 'Le due password non coincidono'};
                }

                const response = await this.api.doChangeAdminPassword(values.value);
                if (response.data.oData === false) {
                    return {result: false, errorText: 'Si è verificato un errore imprevisto, riprova.'};
                }
                return {result: true};
            }
        });
    }

}

export function checkGoodPassword(psw: string): string[] {
    const errors: string[] = [];
    if (psw.length < 8) {
        errors.push('La password deve essere di minimo 8 caratteri');
    }
    if (psw.length > 20) {
        errors.push('La password non può essere più lunga di 20 caratteri');
    }
    if (!/\d/.test(psw)) {
        errors.push('La password deve contenere almeno un numero');
    }
    if (!/[a-z]/.test(psw)) {
        errors.push('La password deve contenere almeno una lettera minuscola');
    }
    if (!/[A-Z]/.test(psw)) {
        errors.push('La password deve contenere almeno una lettera MAIUSCOLA');
    }
    if (containsEmoji(psw)) {
        errors.push('La password non deve contenere Emoji (es. 😀)');
    }

    return errors;
}
