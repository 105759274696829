<ul class="navbar-nav ml-auto">
    <li *appIsClientGuard>
        <div class="dropdown" ngbDropdown placement="auto" >
            <a ngbDropdownToggle href="javascript:" data-toggle="dropdown">
                <i class="icon feather icon-bell badge-container">
                    <span class="badge" *ngIf="notificationsCount > 0">{{notificationsCount}}</span>
                </i>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
                <div class="noti-head">
                    <h6 class="d-inline-block m-b-0">Ultime notifiche</h6>
                </div>
                <perfect-scrollbar [style.max-height]="'300px'">
                    <app-activity-list></app-activity-list>
                </perfect-scrollbar>
                <!--
                <div class="noti-footer">
                    <a routerLink="/ticket">Visualizza tutti i ticket</a>
                </div>-->
            </div>
        </div>
    </li>
    <li>
        <div class="dropdown drp-user" ngbDropdown placement="auto">
            <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
                <i class="icon feather icon-user"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
                <div class="pro-head">
                    <!--img src="assets/images/user/avatar-1.jpg" class="img-radius" alt="User-Profile-Image-->
                    <span>{{username}}</span>
                    <a href="javascript:" class="dud-logout" title="Logout" (click)="logout()">
                        <i class="feather icon-log-out"></i>
                    </a>
                </div>
                <ul class="pro-body">
                    <li *ngIf="isTecnico">
                        Tec: {{profile.tec.user}}
                    </li>
                    <li *ngIf="!isTecnico">
                        <a href="javascript:" class="dropdown-item" routerLink="/contatti">
                            <i class="fas fa-user"></i> Profilo
                        </a>
                    </li>
                    <ng-template [ngIf]="!isTecnico" >
                        <li *appIsClientGuard>
                        <a href="javascript:" class="dropdown-item" (click)="changePassword()">
                            <i class="fas fa-key"></i> Cambia password
                        </a>
                        </li>
                    </ng-template>
                    <li>
                        <a class="dropdown-item" routerLink="/informativa-privacy">
                            <i class="fas fa-sticky-note"></i> Informativa privacy
                        </a>
                    </li>
                    <li>
                        <a href="javascript:" class="dropdown-item" (click)="logout()">
                            <i class="fas fa-sign-in-alt"></i> Scollegati
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </li>
</ul>

<app-change-password [forceChange]="false" #changePasswordComponent></app-change-password>
