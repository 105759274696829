import {Component, EventEmitter} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-input-modal',
    templateUrl: './input-modal.component.html',
    styleUrls: ['./input-modal.component.scss']
})
export class InputModalComponent {

    // =======================================================================
    //      FIELDS
    // =======================================================================

    value = '';
    value2 = '';
    adminPassword = '';
    title: string = null;
    showCancelButton = true;
    introTextForAdminPassword: string = null;
    introText: string = null;
    introTextForInput2: string = null;
    showInput = true;
    inputType: InputModalType = InputModalType.text;
    inputType2: InputModalType = InputModalType.text;
    inputStep = 0.01;
    inputMin = -999999999;
    inputMax = 999999999;
    inputStep2 = 0.01;
    inputMin2 = -999999999;
    inputMax2 = 999999999;
    useInput2 = false;
    placeholder1 = '';
    placeholder2 = '';
    confirmed = new EventEmitter<InputModalResult | string>();
    cancelled = new EventEmitter();
    confirmButtonText?: string;
    cancelButtonText?: string;
    askForAdminPsw?: boolean;
    customCheckFn?: (request: InputModalResult) => Promise<{ result: boolean, errorText?: string }>
        | { result: boolean, errorText?: string };

    errorAdminPassword = false;
    errorInput1 = false;
    errorInput2 = false;
    errorCustomFnText = '';

    // =======================================================================
    //      CONSTRUCTOR
    // =======================================================================

    constructor(public activeModal: NgbActiveModal) {
    }

    // =======================================================================
    //      STATIC METHODS
    // =======================================================================

    static showAlert(modalService: NgbModal, title: string, text: string) {
        InputModalComponent.confirmDialog(modalService, () => {
        }, () => {
        }, {
            title,
            introText: text,
            showCancelButton: false,
            confirmButtonText: 'OK',
        });
    }

    static confirmDialog(modalService: NgbModal, confirm: (adminPassword: string) => void, cancel: () => void, options: {
        title?: string,
        introText?: string,
        confirmButtonText?: string,
        cancelButtonText?: string,
        askForAdminPsw?: boolean,
        showCancelButton?: boolean,
        introTextForAdminPassword?: string
    }) {
        const modal = modalService.open(InputModalComponent, {
            backdrop: 'static',
            windowClass: 'animated fadeIn',
            keyboard: options.showCancelButton ?? true
        });
        const modalComponent = modal.componentInstance as InputModalComponent;
        modalComponent.value = '';
        modalComponent.title = options.title ?? '';
        modalComponent.showInput = false;
        modalComponent.introText = options.introText ?? '';
        modalComponent.confirmButtonText = options.confirmButtonText ?? 'Conferma';
        modalComponent.cancelButtonText = options.cancelButtonText ?? 'Annulla';
        modalComponent.askForAdminPsw = options.askForAdminPsw ?? false;
        modalComponent.introTextForAdminPassword = options.introTextForAdminPassword ?? '';
        modalComponent.showCancelButton = options.showCancelButton ?? true;
        const subscription = modalComponent.confirmed.subscribe(confirm);
        const subscription2 = modalComponent.cancelled.subscribe(cancel);
        modal.result.then(() => {
            subscription.unsubscribe();
            subscription2.unsubscribe();
        });

    }

    static promptInput(modalService: NgbModal, action: (value: InputModalResult) => void, options: {
                           value?: string,
                           value2?: string,
                           inputType?: InputModalType,
                           title?: string,
                           introText?: string,
                           inputStep?: number,
                           inputMin?: number,
                           inputMax?: number,
                           inputStep2?: number,
                           inputMin2?: number,
                           inputMax2?: number,
                           placeholder1?: string,
                           placeholder2?: string,
                           confirmButtonText?: string,
                           cancelButtonText?: string,
                           askForAdminPsw?: boolean,
                           useInput2?: boolean,
                           introTextForAdminPassword?: string,
                           introTextForInput2?: string,
                           showCancelButton?: boolean,

                           customCheckFn?: (values: InputModalResult) => Promise<{ result: boolean, errorText?: string }>
                               | { result: boolean, errorText?: string };
                       }
    ) {
        const modal = modalService.open(InputModalComponent, {
            backdrop: 'static',
            windowClass: 'animated fadeIn',
            keyboard: options.showCancelButton ?? true
        });
        const modalComponent = modal.componentInstance as InputModalComponent;
        modalComponent.value = options.value ?? '';
        modalComponent.value2 = options.value2 ?? '';
        modalComponent.inputType = options.inputType ?? InputModalType.text;
        modalComponent.title = options.title ?? '';
        modalComponent.showInput = true;
        modalComponent.introText = options.introText ?? '';
        modalComponent.inputStep = options.inputStep ?? 0.01;
        modalComponent.inputMin = options.inputMin ?? -999999999;
        modalComponent.inputMax = options.inputMax ?? 999999999;
        modalComponent.inputStep2 = options.inputStep2 ?? 0.01;
        modalComponent.inputMin2 = options.inputMin2 ?? -999999999;
        modalComponent.inputMax2 = options.inputMax2 ?? 999999999;
        modalComponent.useInput2 = options.useInput2 ?? false;
        modalComponent.introTextForAdminPassword = options.introTextForAdminPassword ?? '';
        modalComponent.introTextForInput2 = options.introTextForInput2 ?? '';
        modalComponent.confirmButtonText = options.confirmButtonText ?? 'Conferma';
        modalComponent.cancelButtonText = options.cancelButtonText ?? 'Annulla';
        modalComponent.askForAdminPsw = options.askForAdminPsw ?? false;
        modalComponent.customCheckFn = options.customCheckFn ?? null;
        modalComponent.showCancelButton = options.showCancelButton ?? true;
        modalComponent.placeholder1 = options.placeholder1 ?? '';
        modalComponent.placeholder2 = options.placeholder2 ?? '';
        const subscription = modalComponent.confirmed.subscribe(action);
        modal.result.then(() => {
            subscription.unsubscribe();
        });
    }

    // =======================================================================
    //      METHODS
    // =======================================================================

    isValid(field: string): boolean {
        return field != null && field.trim().length > 0;
    }

    async confirm() {
        this.errorAdminPassword = false;
        this.errorInput1 = false;
        this.errorInput2 = false;
        this.errorCustomFnText = '';


        if (this.askForAdminPsw && !this.isValid(this.adminPassword)) {
            this.errorAdminPassword = true;
            return;
        }

        if (this.showInput && !this.isValid(this.value)) {
            this.errorInput1 = true;
            return;
        }

        if (this.useInput2 && !this.isValid(this.value2)) {
            this.errorInput2 = true;
            return;
        }


        if (this.askForAdminPsw && !this.showInput) {
            // promptConfirm con richiesta di psw amministrativa: restituisce la sola psw amministrativa
            this.confirmed.emit(this.adminPassword);
        } else {
            const values = {value: this.value, value2: this.value2, adminPassword: this.adminPassword};
            // promptInput: restituisce qualsiasi dato sia stato possibile raccogliere
            if (this.customCheckFn != null) {
                const result = await this.customCheckFn(values);
                if (result.result === false) {
                    this.errorCustomFnText = result.errorText ?? '';
                    return; // evita di chiudere il dialogo
                }
            }
            this.confirmed.emit(values);
        }
        this.activeModal.close();
    }

    cancel() {
        this.cancelled.emit();
        this.activeModal.close();
    }
}

export enum InputModalType {
    text = 'text',
    number = 'number',
    email = 'email'
}

export interface InputModalResult {
    value: string;
    value2: string;
    adminPassword: string;
}
