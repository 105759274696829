import {Component, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-speed-test',
    templateUrl: './docs-area.component.html',
})
export class DocsAreaComponent {
    constructor(private sanitizer: DomSanitizer) {
    }
}
