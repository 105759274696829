import {EventEmitter, Injectable, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

export interface MftToast {
    id?: number;
    caption?: string;
    title: string;
    body?: string;
    extraClass?: string;
    timeout?: number;
}

const defaultMftToast: MftToast = {
    title: 'Attenzione',
    caption: '',
    extraClass: '',
    timeout: 5000,
    body: '',
};

@Injectable({
    providedIn: 'root'
})
export class ToastMftService {

    private _events = new BehaviorSubject<MftToast[]>([]);
    readonly events = this._events.asObservable();

    constructor() {
    }

    emitToast(toast: MftToast) {
        this._events.next([
            ...this._events.value.filter(t => t.title !== toast.title),
            {...defaultMftToast, ...toast, id: Date.now().valueOf()}
        ]);
    }

    removeToast(id: number) {
        this._events.next(this._events.value.filter(t => t.id !== id));
    }

    clearToasts() {
        this._events.next([]);
    }
}
